/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import '../global.css';

/** @typedef {import("react").ReactNode } ReactNode */

/**
 * @arg {{children: ReactNode}} _
 */
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer className="container mx-auto p-gut text-sm text-gray-dark text-right">
        © {new Date().getFullYear()}, PagerDuty
      </footer>
    </>
  );
};

export default Layout;
