import { Link } from 'gatsby';
import React from 'react';
import PdLogo from '../images/pd-logo.svg';

const Header = () => (
  <header className="text-black bg-white h-lane flex items-center">
    <div className="container mx-auto flex items-center relative h-3gut">
      <a href="/" className="w-2lane ml-gut mr-auto">
        <img src={PdLogo} />
        <div className="pd-logo-audience">PLATFORM</div>
      </a>
      <input id="Submenu-toggle" type="checkbox" className="hidden"/>
      <label className="p-gut hover:bg-gray-light md:hidden"
             id="Submenu-label"
             htmlFor="Submenu-toggle">
        <svg className="w-2gut h-2gut fill-current">
          <rect y="3"  width="32" height="3" />
          <rect y="11" width="32" height="3" />
          <rect y="15" width="32" height="3" className="checked" />
          <rect y="19" width="32" height="3" />
          <rect y="27" width="32" height="3" />
        </svg>
      </label>
      <div className="hidden absolute right-0 top-0 z-menu mt-4gut flex flex-col
                    md:static md:mt-0 md:block"
           id="Submenu">
        <svg className="mr-gut ml-auto w-2gut h-gut text-white md:hidden">
          <polygon points="4,16 16,0 28,16" fill="currentColor"/>
        </svg>
        <ul className="w-3lane md:w-auto md:flex md:flex-row">
          <li className="lg:w-2lane">
            <a href="https://api-reference.pagerduty.com/" rel="noopener"
               className="bg-white hover:bg-gray-light px-gut flex justify-center items-center h-3gut">
              <span>API References</span>
            </a>
          </li>
          <li className="lg:w-2lane">
            <a href="https://v2.developer.pagerduty.com/docs" rel="noopener"
               className="bg-white hover:bg-gray-light px-gut flex justify-center items-center h-3gut">
              <span>Documentation</span>
            </a>
          </li>
          <li className="lg:w-2lane">
            <a href="https://community.pagerduty.com/c/dev?utm_source=web&utm_campaign=dev_top_nav&utm_medium=a"
               rel="noopener"
               className="bg-white hover:bg-gray-light px-gut flex justify-center items-center h-3gut">
              <span>Community</span>
            </a>
          </li>
          <li className="lg:w-2lane">
            <Link to="/sign-up/"
                  className="text-white bg-green hover:bg-green-dark px-gut flex justify-center items-center h-3gut">
              <span>Get started</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </header>
);

export default Header;
